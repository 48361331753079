import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PostList from "../../components/postList"

function QuotesPage({ data, location }) {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location}>
      <Seo title="Quotes Page Title" description="Quotes page description" />
      <main>
        <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
          <div className="py-16">
            <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-tight tracking-tight text-gray-900 mb-8 sm:mb-10">
              Quotes
            </h1>
            <p className="max-w-4xl text-lg sm:text-2xl sm:leading-10">
              Get best UI, UX and product design links, delivered to your inbox
              every week. No spam, unsubscribe anytime.
            </p>
          </div>
          <div className="mb-16">
            <PostList posts={posts} />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default QuotesPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "quotes" } } }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          date
        }
      }
    }
  }
`
